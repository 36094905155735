var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "panel-wrapper pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Payment Methods" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "panel-tabs",
                      attrs: { "slider-color": "primary" },
                      model: {
                        value: _vm.active,
                        callback: function($$v) {
                          _vm.active = $$v
                        },
                        expression: "active"
                      }
                    },
                    _vm._l(_vm.tabs, function(tab, i) {
                      return _c(
                        "v-tab",
                        {
                          key: i,
                          attrs: { to: tab.path, "active-class": "tab-active" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(tab.text) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "slide", mode: "out-in" } },
                    [_c("router-view")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }