<template>
    <v-container class="panel-wrapper pa-0" fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="Payment Methods">
                    <v-tabs class="panel-tabs"
                            v-model="active"
                            slider-color="primary">
                        <v-tab v-for="(tab, i) in tabs" :key="i"
                               :to="tab.path"
                               active-class="tab-active">
                            {{tab.text}}
                        </v-tab>
                    </v-tabs>
                    <transition
                            name="slide"
                            mode="out-in">
                        <router-view></router-view>
                    </transition>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import TabCard from '@/components/TabCard.vue'
    export default {
        name: "PaymentMethod",
        data(){
            return {
                active: 0,
                tabs : [
                    {icon: '', text: 'Credit Card', path: `/billing/paymentmethod/credit`},
                    {icon: '', text: 'Paypal', path: `/billing/paymentmethod/paypal`},
                ]
            }
        },
        components: {
            TabCard
        },
        created() {
            this.$SDK.track({
                pageName : 'Billing_PaymentMethods'
            })
        }
    }
</script>

<style lang="scss">
    .panel-pay{
        min-height: calc(100vh - 204px);
    }

</style>
